.containedBox {
  height: 100vh;
  overflow-y: auto;
}

.mapView {
  height: 75vh;
}

.sideView {
  height: 75vh;
  overflow-y: auto;
  background: lightgray;
  border-style: double;
  border-color: gray;
}

.detailsView {
  height: 25vh;
  background: lightgray;
  border-style: double;
  border-color: gray;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.info h6 {
  margin: 0 0 5px;
  color: #777;
}

.sidepanel {
    background: lightgray;
    display: flex;
    flex-direction: column;
}

.nav-link {
  background-color: lightgray;
}

.nav-link.active {
  background-color: gray !important;
  font-weight: bold !important;
  color: black !important;
}