.card {
    background: lightgray;
}

.body {
    background: lightgray;
}

.title {
    background: darkgray;
    border-radius: 5px;
}

.text {
    background: lightgray;
}