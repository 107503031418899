.detailPanel {
    background: lightgray;
    height: 100%;
    display: flex;
    flex-direction: column
}
  
.stickyHeader {
    background-color: darkgray;
    border-bottom-style: groove;
    position: sticky;
    top: 0;
    z-Index: 1;
}

.keyPhrase {
    background-color: lightyellow;
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
}